<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;支付配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
      <el-form-item label="启用支付宝">
           <el-radio-group v-model="form.alpay_is_off" size="mini">
          <el-radio-button label="1">启用</el-radio-button>
          <el-radio-button label="0">不启用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="支付宝AppId">
        <el-input v-model="form.pay_app_id"></el-input>
      </el-form-item>
      <el-form-item label="支付宝私钥">
        <el-input v-model="form.app_private_key" type="textarea" :rows="6"></el-input>
      </el-form-item>
      <el-form-item label="应用公钥">
        <el-input v-model="form.ali_pay_public_key" type="textarea" :rows="6"></el-input>
      </el-form-item>
      <el-form-item label="支付宝公钥">
        <el-input v-model="form.ali_pay_real_public_key"  type="textarea" :rows="6"></el-input>
      </el-form-item>
      <el-form-item label="启用微信付款">
         <el-radio-group v-model="form.wx_pay_is_off" size="mini">
          <el-radio-button label="1">启用</el-radio-button>
          <el-radio-button label="0">不启用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="微信AppId">
        <el-input v-model="form.wx_pay_app_id"></el-input>
      </el-form-item>
      <el-form-item label="商户号">
        <el-input v-model="form.mch_id"></el-input>
      </el-form-item>
      <el-form-item label="商户秘钥">
        <el-input v-model="form.mch_key"></el-input>
      </el-form-item>
      <el-form-item label="商户证书路径">
        <el-input v-model="form.certificate_url"></el-input>
      </el-form-item>
      <el-form-item label="小程序AppId">
        <el-input v-model="form.applet_id"></el-input>
      </el-form-item>
      <el-form-item label="小程序Secret">
        <el-input v-model="form.applet_secret"></el-input>
      </el-form-item>
      <el-form-item label="证书序列号">
        <el-input v-model="form.certificate_no"></el-input>
      </el-form-item>
      <el-form-item label="证书路径">
        <el-input v-model="form.certificate_key_url"></el-input>
      </el-form-item>
      <el-form-item label="ApiV3秘钥">
        <el-input v-model="form.api_V3_secret_key"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        alpay_is_off: "",
        pay_app_id: "",
        app_private_key: "",
        ali_pay_public_key: "",
        ali_pay_real_public_key: "",
        wx_pay_is_off: "",
        wx_pay_app_id: "",
        mch_id: "",
        mch_key: "",
        certificate_url: "",
        applet_id: "",
        applet_secret: "",
        certificate_no: "",
        certificate_key_url: "",
        api_V3_secret_key: ""
      }
    };
  },
  created() {
    this.initSms();
  },
  methods: {
    initSms() {
      getSystemConfigApi({ type: "pay" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>